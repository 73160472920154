<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Information",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    VueEasyLightbox,
  },
  data() {
    return {
      title: "Information",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idvehicule: "",

      marque: "",
      model: "",
      type: "",
      kilometrage: "",
      nombreplace: "",
      motorisation: "",
      climatisation: "",
      boite: "",
      immatriculation: "",
      kmAuCompteur: 0,
      kmAvantVidange: 0,
      anneeMiseEnCirculation: "",
      nombreSieges: 0,
      couleur: "",
      numeroAssurance: "",
      assurance: "",
      observations: "",
      prixPublieJournalier: 0,
      prixPublieJournalierHorsVille: 0,
      statut: "",
      lieu: "",
      chauffeur: "",
      vehiculePicture: [],
      face: null,
      interieur: null,
      vueInterieur: null,
      arriere: null,

      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,

      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
    };
  },

  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.idvehicule = this.$route.params.idvehicule;
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/entreprise/detail-location/?id=" + this.idvehicule,
        {},
        false
      );

      //console.log("data.data:", data.data);
      if (data && data.data) {
        let location = data.data.vehicule;

        console.log("location:", location);

        this.marque = location.marque;
        this.model = location.model;
        this.immatriculation = location.immatriculation;
        this.numeroAssurance = location.numeroAssurance;
        this.assurance = location.compagnieAssurance;
        this.prixPublieJournalier = location.prixPublieJournalier;
        this.prixPublieJournalierHorsVille =
          location.prixPublieJournalierHorsVille;
        this.type = location.types;
        this.motorisation = location.motorisation;
        this.climatisation = location.climatisation;
        this.boite = location.boite;
        this.couleur = location.couleur;
        this.anneeMiseEnCirculation = location.anneeMiseEnCirculation;
        this.nombreSieges = location.nombreSieges;
        this.kmAuCompteur = location.kmAuCompteur;
        this.kmAvantVidange = location.kmAvantVidange;
        this.lieu = location.lieu.nom;
        this.chauffeur =
          location.chauffeur.nom + " " + location.chauffeur.prenom;
        this.statut = location.statut;
        this.observations = location.observations;

        this.vehiculePicture = location.vehiculePicture;
        //console.log("this.vehiculePicture:", this.vehiculePicture);
        this.face =
          this.vehiculePicture[0] == ""
            ? require("@/assets/images/users/attente.png")
            : this.vehiculePicture[0].replace("imagevehicule", "imagev");
        this.interieur =
          this.vehiculePicture[1] == ""
            ? require("@/assets/images/users/attente.png")
            : this.vehiculePicture[1].replace("imagevehicule", "imagev");
        this.vueInterieur =
          this.vehiculePicture[2] == ""
            ? require("@/assets/images/users/attente.png")
            : this.vehiculePicture[2].replace("imagevehicule", "imagev");
        this.arriere =
          this.vehiculePicture[3] == ""
            ? require("@/assets/images/users/attente.png")
            : this.vehiculePicture[3].replace("imagevehicule", "imagev");

        /*
        chauffeur.permis == ""
            ? require("@/assets/images/users/attente.png")
            : `https://rmobility.ovh/location/image/?codeImage=` +
              chauffeur.permis;
        
        {
    "id": 1,
    
    
    "dateCreation": "2021-07-18T15:34:12.117Z",
    "isVisible": true,
    "vehiculePicture": [
        "https://rmobility.ovh/imagevehicule/1",
        "https://rmobility.ovh/imagevehicule/2",
        "https://rmobility.ovh/imagevehicule/3",
        "https://rmobility.ovh/imagevehicule/4"
    ],
    "reservation": []
}*/

        /*this.imageProfil =
          location.profil == ""
            ? require("@/assets/images/users/attente.png")
            : `https://rmobility.ovh/location/image/?codeImage=` +
              location.profil;

        this.imageCni =
          location.cni == ""
            ? require("@/assets/images/users/attente.png")
            : `https://rmobility.ovh/location/image/?codeImage=` +
              location.cni;

        this.imagePermis =
          location.permis == ""
            ? require("@/assets/images/users/attente.png")
            : `https://rmobility.ovh/location/image/?codeImage=` +
              location.permis;*/
      }

      /*const dataActivity = await apiRequest(
        "GET",
        "admin/trajets/activites/?id=" + this.idvehicule,
        undefined,
        false
      );
      //console.log("data.activity:", dataActivity.data);

      if (dataActivity && dataActivity.data) {
        console.log(dataActivity.data);
        this.activity.publies = dataActivity.data.conducteur.publie
          ? dataActivity.data.conducteur.publie
          : "0";
        this.activity.voyages_effectues = dataActivity.data.passager
          .voyagesEffectues
          ? dataActivity.data.passager.voyagesEffectues
          : "0";
      }*/
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },

    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },
    async addProfil() {
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("idChauffeur", this.idvehicule);
        formData.append("pic", this.fileProfil);

        const returnFileProfil = await apiRequest(
          "POST",
          "admin/entreprise/location-profile",
          formData,
          true
        );

        if (returnFileProfil && returnFileProfil.data) {
          //console.log("Profil", returnFileProfil.data);
          this.showModalprofil = false;
          this.init();
          this.position();
        }
      }
    },

    async addCNI() {
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idChauffeur", this.idvehicule);
        formData.append("cni", this.fileCni);

        const returnFilecni = await apiRequest(
          "POST",
          "admin/entreprise/location-cni",
          formData,
          true
        );

        if (returnFilecni && returnFilecni.data) {
          this.showModalcni = false;
          this.init();
          this.position();
        }
      }
    },

    async addPermis() {
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idChauffeur", this.idvehicule);
        formData.append("permis", this.filePermis);

        const returnFilePermis = await apiRequest(
          "POST",
          "admin/entreprise/location-permis",
          formData,
          true
        );

        if (returnFilePermis && returnFilePermis.data) {
          this.showModalpermis = false;
          this.init();
          this.position();
        }
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },

  CloseModalProfil() {
    this.showModalprofil = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Marque: {{ marque }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Model: {{ model }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Immatriculation: {{ immatriculation }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Numero Assurance: {{ numeroAssurance }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Assurance: {{ assurance }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Type: {{ type }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Motorisation: {{ motorisation }}
                </div>
              </div>
              <div class="col-md-4">
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Climatisation: {{ climatisation ? "OUI" : "NON" }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Boite: {{ boite }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Couleur: {{ couleur }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Annee: {{ anneeMiseEnCirculation }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Sièges: {{ nombreSieges }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Km. Compteur : {{ kmAuCompteur }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Km. Vidange : {{ kmAvantVidange }}
                </div>
              </div>
              <div class="col-md-4">
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Prix Journalier: {{ prixPublieJournalier }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Prix Journalier HorsVille: {{ prixPublieJournalierHorsVille }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Lieu : {{ lieu }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Chauffeur: {{ chauffeur }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Statut: {{ statut }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Observations: {{ observations }}
                </div>
              </div>

              <!--<div class=" col-md-2 ml-5 float-right" style="margin-top:80px;">
                <div v-if="this.user.active == true">
                  <button
                    type="submit"
                    @click="desactiverUser"
                    class="btn btn-warning mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Désactiver ce
                    compte
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    @click="ActiverUser"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Activer ce compte
                  </button>
                </div>
                <button type="submit" class="btn btn-danger mb-2 mr-2">
                  <i class="ri-close-line align-middle mr-2"></i>Supprimer ce
                  compte
                </button>
              </div>-->
            </div>

            <div class="row mt-5">
              <div class="col-md-12">
                <div class="row center" style="text-align:center;">
                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">1</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.face}`"
                          width="200"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                      <!--<div class="row justify-content-center mt-4">
                        <div>
                          <button
                            @click="showModalprofil = true"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">2</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.interieur}`"
                          width="200"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                      <!--<div class="row justify-content-center mt-4">
                        <div>
                          <button
                            @click="showModalcni = true"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">3</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.vueInterieur}`"
                          width="200"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                      <!--<div class="row justify-content-center mt-4">
                        <div>
                          <button
                            @click="showModalpermis = true"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">4</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.arriere}`"
                          width="200"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                      <!--<div class="row justify-content-center mt-4">
                        <div>
                          <button
                            @click="showModalpermis = true"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </div>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalprofil"
      title="Téléverser ce profil ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileProfil"
          ref="fileProfil"
          v-on:change="handleFileProfil"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalprofil = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addProfil"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalcni"
      title="Téléverser cette CNI ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileCni"
          ref="fileCni"
          v-on:change="handleFileCni"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalcni = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addCNI"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalpermis"
      title="Téléverser ce permis ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="filePermis"
          ref="filePermis"
          v-on:change="handleFilePermis"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalpermis = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addPermis"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriquePassager"
      title="Historique Passager"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="passagerData"
              :fields="passager"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- CHANGER LE MOT DE PASSE -->
    <b-modal
      v-model="showModalChangePass"
      title="Changer le mot de passe"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Mot de passe"
            v-model="newpassword"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Confirmation Mot de passe"
            v-model="confnewpassword"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangePass = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changePass">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showChange"
      title="Mot de passe modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showChange = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecChange"
      title="Erreur Reéssayez"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecChange = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <!-- -->
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
</style>

<!--<b-tabs pills justified content-class="p-3 text-muted">
            <b-tab class="border-0">
              
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-format-list-bulleted mr-2"></i
                  >Activités</span
                >
              </template>
              <div>
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor:pointer"
                    class="text-success"
                    @click="HistoriqueConducteur = true"
                    >conducteur</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets publiés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">{{ activity.publies }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets effectués
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-success">{{ activity.effectues }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets annulés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-info">{{ activity.annulees }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="border border-warning"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets à venir
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-warning">{{ activity.a_venir }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="blueG"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Gains
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-blueG">{{ activity.gains }} XOF</h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor:pointer"
                    class="text-success"
                    @click="HistoriquePassager = true"
                    >passager</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Réservations éffectuées
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">
                          {{ activity.reservations_effectuees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Voyages effectués
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-success">
                          {{ activity.voyages_effectues }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Réservations annulés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-info">
                          {{ activity.reservations_annulees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>-->
